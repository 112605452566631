<template>
  <div class="shop"
    style="font-size: 18px; padding-bottom: 35px">
    <div class="center"
      v-for="item in list"
      :key="item.index"
      style="border: 1px solid rgb(222 206 206)">
      <div class="shopTitle van-ellipsis"
        style="padding-top: 0; padding-bottom: 0">
        {{ item.Title }}
      </div>
      <div class="Content"
        style="padding: 8px 10px">
        <div style="display: inline-block"
          @click="jtXq(item.HId)">
          <div style="font-size: 16px; color: #333; margin: 5px 0">
            <span style="margin-right: 20px">姓名：{{ item.Name }}
              <i class="iconfont icon-junren"
                style="color: red"
                v-if="item.Veterans == '是'"></i>
              <i class="iconfont icon-dangyuan"
                style="color: red"
                v-if="item.PartyMember == '是'"></i>
              <i class="iconfont icon-canjirenzheng"
                style="color: red"
                v-if="item.Disabled == '是'"></i>
            </span>
          </div>
          <div class="centertime"
            style="color: #333; font-size: 16px; margin: 5px 0">
            <span>手机号：{{ item.Mobile }}</span>
          </div>
          <span class="centertime"
            style="color: #333; font-size: 16px; margin: 5px 0">
            <span>状态：{{ item.StatusDesc }}</span>
          </span>
          <div class="centertime"
            v-if="item.Address != ''"
            style="color: #333; font-size: 16px; margin: 5px 0">
            <span>住址：{{item.Address}}</span>
          </div>
        </div>
        <div class="hidAddOn">
          <van-button round
            type="info"
            size="mini"
            style="text-center: right; font-size: 14px"
            color="#617bfa"
            @click="jtbianji(item)">编辑</van-button>
          <van-button round
            type="danger"
            size="mini"
            style="text-center: right; font-size: 14px"
            @click="delet(item)">删除</van-button>
        </div>
      </div>
    </div>
    <!-- <div style="text-align:right;margin-right:10px;"><van-button round type="info" size="mini" style="text-center:right;margin-right:10px;margin-bottom:10px;">编辑</van-button></div> -->

    <div>
      <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
        <van-button round
          type="info"
          size="normal"
          style="text-center: right; font-size: 16px; width: 100%"
          @click="addXq()"
          color="#617bfa">新增员工</van-button>
      </div>
    </div>
    <!-- <van-popup v-model:show="show" position="top" :style="{ height: '30%' }" /> -->
    <!-- 没有更多数据了 -->
    <div v-if="hidden"
      class="hidden">───── 没有更多数据了 ─────</div>

  </div>
</template>
<script>
import {
  WxGetEntStaffPage,
  WxDeleteStaff
} from "@/api/RealInfo";
import { getwgToken, setwgToken } from "@/utils/auth";
import { setOpenId, getOpenId } from "@/utils/auth";
import { Col } from "vant";
import { Dialog } from "vant";
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
export default {
  data () {
    return {
      riKind: 0, //tab栏
      hzlist: {}, //户主
      list: {},
      listfrom: {
        page: 1,
        limit: 99,
      }, //查询传参
      spfrom: {
        oCode: "",
        gId: "",
      },
      search: "", //搜索内容
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
      show: false,
      wushuju: false,
      zhfrom: {
        page: 1,
        limit: 10,
        bFaId: "",
      },
      splist: {},
      bdlist: {},
      bdfrom: {},
      ydfrom: {},
      spShow: false,
      spJtShow: false,
    };
  },
  created () {
    this.getList();
  },
  methods: {
    // 获取员工列表
    getList () {
      console.log(this.$route.params);
      this.listfrom.ekind = this.$route.params.ekind;
      this.listfrom.eid = this.$route.params.seId;
      WxGetEntStaffPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            console.log(res);
            this.list = res.data.data
          }
        })
        .catch(() => { });
    },
    add: function (row) {
      console.log(row);
      this.itemmark = JSON.stringify(row.BFaId);
      console.log(this.itemmark);
      this.$router.push({
        name: "jtbianji",
        query: { BFaId: this.itemmark, add: 1, BRId: row.BRId },
      });
    },

    delet: function (row) {
      console.log(row);
      Dialog.confirm({
        title: "提示",
        message: "确定要删除该员工信息吗",
      })
        .then(() => {
          // on confirm
          WxDeleteStaff({ OpenID: getOpenId(), StaffId: row.StaffId })
            .then((res) => {
              console.log(res.data.code);
              if (res.data.code == 0) {
                Toast.success("删除成功");
                this.getList();
              } else {
                Toast.fail(res.data.msg);
              }
            })
            .catch(() => { });
        })
        //
        .catch(() => {
          // on cancel
          console.log(123);
        });
    },
    jtXq: function (HId) {
      console.log(HId);
      this.itemmark = JSON.stringify(HId);
      this.$router.push({
        name: "chengyuanXq",
        query: { HId: this.itemmark },
      });
    },
    jtbianji: function (row) {
      console.log(row);
      // this.itemmark = JSON.stringify(HId);
      this.$router.push({
        path: "/shop/myShop/ygList/addYg/" + row.EId + "/" + row.EKind + "/" + row.StaffId,
      });
    },
    addXq: function () {
      console.log(this.$route);
      this.itemmark = this.$route.params;
      console.log(this.itemmark);
      this.$router.push({
        path: "/shop/myShop/ygList/addYg/" + this.itemmark.seId + "/" + this.itemmark.ekind + "/" + 2,
        // query: { seId: this.itemmark.seId, ekind: this.itemmark.ekind },
      });
    },
    updataXq: function (item) {
      console.log(item.BFaId);
      this.itemmark = JSON.stringify(item.BFaId);
      this.$router.push({
        name: "roomAdd",
        query: { seId: this.itemmark.seId, ekind: this.itemmark.ekind },
      });
    },
    onClose () {
      this.setData({ show: false });
    },
    // tan点击切换事件
    onClick (e) {
      console.log(123123);
      console.log(e);
      this.fwList(e);
    },
    // 搜索
    onSearch () {
      this.listfrom.page = 1;
      this.getList();
    },
    // 时间格式化
    dataFormat (value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
};
</script>
<style scoped>
.jiating {
  width: 45%;
}
.shopTitle {
  color: #333;
  padding: 10px;
}
.shop .information .center {
  position: relative;
  border-bottom: none;
  padding: 0px;
  border-radius: 5px;
}
.shop .center {
  width: 92%;
  margin: 10px auto;
  background: white;
}
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
.hidAddOn {
  position: relative;
  text-align: right;
  margin-right: 10px;
}
.hidAddOn button {
  display: inline-block;
  padding: 12px;
}
.label {
  font-size: 16px;
}
</style>